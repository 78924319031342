import Header from './Header';
import AppBody from './AppBody';
import Footer from './Footer';
import './css/App.css';

function App(): React.ReactElement {
  return (
    <div className="App">
      <Header />
      <AppBody />
      <Footer />
    </div>
  );
}

export default App;
