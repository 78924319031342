import { LoadingOutlined } from '@ant-design/icons';
import { Button, Card, List, message, Popconfirm, Progress, Steps, Typography, Upload } from 'antd';
import produce from 'immer';
import { useState } from 'react';
import FileUploadPreview from './FileUploadPreview';
import { ReactComponent as UploadIcon } from './images/upload_icon.svg';
import { getPresignedPostURLs, uploadFile } from './services/FileUpload';
import { getSwatchList, swatchListToCSV } from './services/Swatch'
import { createSessionID } from './services/Session';
import type { SwatchList } from './services/Swatch'

type FileState = 'waiting' | 'pending' | 'uploading' | 'success' | 'error';

type StepState = 'waiting' | 'uploading' | 'processing' | 'done'

type FileStates = {
  [fileName: string]: FileState;
};

function upsertFileState(
  existingStates: FileStates,
  fileName: string,
  state: FileState,
): FileStates {
  return produce(existingStates, (draftState) => {
    draftState[fileName] = state;
  });
}

function countByState(existingStates: FileStates, state: FileState): number {
  return (
    Object.keys(existingStates).reduce((acc, v) => {
      if (existingStates[v] === state) return acc + 1;
      else return acc;
    }, 0)
  );
}

function removeFileState(existingStates: FileStates, fileName: string): FileStates {
  return produce(existingStates, (draftState) => {
    delete draftState[fileName];
  });
}

function UploadArea(): React.ReactElement {
  const [files, setFiles] = useState<Array<File>>([]);
  const [fileStates, setFileStates] = useState<FileStates>({});
  const [loading, setLoading] = useState(false);
  const [stepState, setStepState] = useState<StepState>('waiting')
  const [processedFiles, setProcessedFiles] = useState<number>(0)
  const [fileHexCodes, setFileHexCodes] = useState<SwatchList>({})
  const [sessionID, setSessionID] = useState(createSessionID())

  const resetState = (): void => {
    setFiles([])
    setFileStates({})
    setLoading(false)
    setStepState('waiting')
    setProcessedFiles(0)
    setFileHexCodes({})
    setSessionID(createSessionID())
  }

  const removeFile = (fileName: string): void => {
    setFiles((files) => files.filter((f) => f.name !== fileName));
    setFileStates(removeFileState(fileStates, fileName));
  };

  const handleSelectedFile = (file: File): void => {
    // ensure we keep file names unique
    const existingFileNames = files.map((f) => f.name);
    if (existingFileNames.indexOf(file.name) === -1) {
      setFiles((existingFiles) => existingFiles.concat([file]));
      setFileStates((existingState) => upsertFileState(existingState, file.name, 'waiting'))
    }
  };

  const swatchCheckLoop = (initialWaitSeconds: number) => {
    setTimeout(() => {
      lookupSwatches()
    }, initialWaitSeconds * 1000)
  }

  const lookupSwatches = async (): Promise<void> => {
    try {
      const swatchList = await getSwatchList(sessionID)
      setFileHexCodes(swatchList)
      const count = Object.keys(swatchList).length
      setProcessedFiles(count) // naive dunno which ones specifically are finished yet
      if (count < files.length) {
        swatchCheckLoop(1)
      } else {
        setStepState('done')
      }
    } catch (e) {
      console.log(e)
      swatchCheckLoop(1)
    }
  }

  const uploadFiles = async (): Promise<void> => {
    setStepState('uploading')
    setLoading(true);
    try {
      const response = await getPresignedPostURLs(files.map((f) => f.name), sessionID);
      await Promise.all(
        files.map(async (f) => {
          setFileStates((existingStates) => upsertFileState(existingStates, f.name, 'uploading'));
          try {
            await uploadFile(f, response[f.name]);
            setFileStates((existingStates) => upsertFileState(existingStates, f.name, 'success'));
          } catch (e) {
            console.log(e);
            setFileStates((existingStates) => upsertFileState(existingStates, f.name, 'error'));
          } finally {
            setLoading(false);
          }
        }),
      );
    } catch (e) {
      message.error(e.message);
      console.log(e);
    } finally {
      setLoading(false);
      setStepState('processing')
      swatchCheckLoop(1)
    }
  };

  const getCurrentStep = () => {
    switch (stepState) {
      case 'uploading': return 0
      case 'processing': return 1
      case 'done': return 2
      default:
        return -1
    }
  }

  const getStepIcon = (step: StepState): React.ReactNode => {
    if (step === stepState) {
      return <LoadingOutlined />
    } else {
      switch (step) {
        case 'waiting': return null
        case 'processing': return null
        case 'done': return null
      }
    }
  }

  const downloadCSV = async () => {
    const csvStr = await swatchListToCSV(fileHexCodes)
    const blob = new Blob([csvStr], { type: 'text/csv;charset=utf-8;' });
    const downloadURI = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.setAttribute("href", downloadURI);
    link.setAttribute("download", "swatchable.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="upload-container">
      <h2 id="generate-swatches">Generate Swatches</h2>
      <div className="swatch-generating-area">
        <Card className="upload-card">
          {stepState === 'waiting' && (
            <Upload.Dragger
              accept="image/*"
              multiple={true}
              beforeUpload={(file: File) => {
                handleSelectedFile(file)
                return false
              }}
              showUploadList={false}
            >
              <div className="upload-call-to-action">
                <UploadIcon className="upload-icon" />
                <div>Drag and drop to upload</div>
                <div>
                  or <Typography.Link target="_blank">click to browse</Typography.Link>
                </div>
              </div>
            </Upload.Dragger>
          )}
          {stepState !== 'waiting' && (
            <Steps style={{ marginBottom: '20px' }} current={getCurrentStep()}>
              <Steps.Step title={'Uploading'} description="We are uploading your images" icon={getStepIcon('uploading')} subTitle={`${countByState(fileStates, 'success')} / ${files.length}`} />
              <Steps.Step title={'Processing'} description="We are generating your swatches" icon={getStepIcon('processing')} subTitle={`${processedFiles} / ${files.length}`} />
            </Steps>
          )}
          {files.length > 0 && (
            <div className="file-list">
              <List
                itemLayout="horizontal"
                dataSource={files}
                rowKey={(item) => item.name}
                renderItem={(item) => (
                  <FileUploadPreview
                    image={item}
                    uploadState={fileStates[item.name]}
                    hexCode={fileHexCodes[item.name]}
                    removeFile={() => removeFile(item.name)}
                  />
                )}
              />
            </div>
          )}
          {files.length > 0 && stepState === 'waiting' && (
            <div className="upload-button-box">
              <Popconfirm
                placement="topRight"
                title={'Are you sure you want to clear all images?'}
                onConfirm={(e) => {
                  e?.stopPropagation();
                  resetState()
                }}
                onCancel={(e) => {
                  e?.stopPropagation();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className="buttons reset-btn"
                  danger
                  disabled={files.length === 0}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Cancel
                </Button>
              </Popconfirm>
              <Button
                className="buttons primary-button"
                type="primary"
                loading={loading}
                disabled={files.length === 0}
                onClick={(e) => {
                  e.stopPropagation();
                  uploadFiles();
                }}
              >
                Upload All ({files.length})
              </Button>
            </div>
          )}
          {stepState === 'done' && (
            <div style={{ paddingTop: '20px' }}>
              <Button
                className="buttons reset-btn"
                danger
                style={{ float: 'left' }}
                disabled={files.length === 0}
                onClick={(e) => {
                  e.stopPropagation();
                  resetState()
                }}
              >
                Reset
              </Button>

              <Button
                className="buttons primary-button"
                style={{ float: 'right' }}
                disabled={files.length === 0}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadCSV()
                }}
              >
                Download CSV
              </Button>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}

export default UploadArea;
