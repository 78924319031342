import handleViewport from 'react-in-viewport';

type Props = {
    children: React.ReactNode;
    defaultClass?: string;
    activeClass?: string;
    repeating: boolean;
    visibilityThreshold?: number;
};

/**
 * Wrap children ReactNodes in a viewport div with a toggling class 'activeClass'.
 * 'activeClass' will toggle when the block enters the viewport. Default class prefix
 * will be "viewport-block", toggling class behaviour: "viewport-block {activeClass}".
 * Example use: 
 * ```<ViewportWrapper activeClass='active'> <p>Hello World!</p> </ViewportWrapper>```
 * @param {string} props.activeClass - the toggling class name
 * @param {React.ReactNode} props.children - the ReactNode which should be wrapped in toggling div
 * @param {boolean} props.repeating - specifies if the toggling className should toggle on every view or not. Default value is true.
 */
function ViewportWrapper({ children, defaultClass = "viewport-block", activeClass = "active", repeating = true, visibilityThreshold = 0 }: Props): React.ReactElement {
    // Intersection observer options
    const options = {
        rootMargin: '0px',
        threshold: visibilityThreshold
    }

    // Initialise the wrapper Block
    const Block = (props: { inViewport: boolean, enterCount: number, forwardedRef: any }) => {
        const { inViewport, enterCount, forwardedRef } = props;
        let viewportClass = '';
        if (enterCount > 0 && !repeating) {
            viewportClass = activeClass;
        } else {
            viewportClass = inViewport ? activeClass : '';
        }
        return (
            <div className={`${defaultClass} ${viewportClass}`} ref={forwardedRef}>
                {children}
            </div>
        );
    }
    // Invoke viewport handling to the initialised Block
    const ViewportBlock = handleViewport(Block, options);

    return <ViewportBlock />
}

export default ViewportWrapper;
