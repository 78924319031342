import UploadArea from './UploadArea';
import SmoothAnchor from './SmoothAnchor';
import ViewportWrapper from './ViewportWrapper';
import { ReactComponent as HeroBanner } from './images/banner.svg';
import { ReactComponent as LeftFloatingSwatches } from './images/left-floating-swtchs.svg';
import { ReactComponent as RightFloatingSwatches } from './images/right-floating-swtchs.svg';
import { ReactComponent as HowItWorksImageBanner } from './images/how-it-works-hero.svg';

function AppBody(): React.ReactElement {

    return (
        <div className="app-body">
            <div className="lf-flt-swatches">
                <LeftFloatingSwatches className="floating-swatches" />
            </div>
            <div className="banner-content">
                <h1 className="tag-line">Swatchable.io - Your eCommerce Swatches made easy</h1>
                <p className="banner-text">A simple to use online image colour processing service, scalable to process hundreds of images at a time.</p>
                <div className="button-box">
                    <div className="buttons primary-button">
                        <SmoothAnchor
                            url='#generate-swatches'
                            label='Generate Swatches'
                            customClass='primary-anchor'
                        />
                    </div>
                    <div className="buttons secondary-button">
                        <SmoothAnchor
                            url='#how-it-works'
                            label='How it Works'
                            customClass='secondary-anchor'
                        />
                    </div>
                </div>
            </div>
            <div className="banner-image">
                <HeroBanner className="hero-image" />
            </div>
            <div className="rt-flt-swatches">
                <RightFloatingSwatches className="floating-swatches" />
            </div>
            <UploadArea />
            <ViewportWrapper defaultClass="how-it-works" activeClass='active' repeating={true} visibilityThreshold={0.10}>
                <h2 id="how-it-works">How it Works</h2>
                <div className="hiw-container">
                    <div className="hiw-content">
                        <p className="hiw-description" >It’s as easy as 1, 2, 3: Simply upload your product images, Swatchable.io will determine the dominant colours and then copy or download the generated swatch hexcodes.</p>
                        <p className="hiw-note">The processing works best on images with the product centered and a consistent background.</p>
                    </div>
                    <ViewportWrapper defaultClass="hiw-img-container" activeClass='active' repeating={false} visibilityThreshold={0.1}>
                        <HowItWorksImageBanner className='hiw-banner' />
                    </ViewportWrapper>
                </div>
            </ViewportWrapper>
            <ViewportWrapper defaultClass="feedback" activeClass='active' repeating={true} visibilityThreshold={0.10}>
                <h2 id="feedback">Feedback</h2>
                <p>We’re always aiming to improve the image processing, feel free to contact us to let us know any issues found or questions you have. </p>
                <a href={"mailto:swatchableio@gmail.com?subject=Swatchable.io Site Enquiry"} className="contact-cta secondary-button">Contact Us</a>
            </ViewportWrapper>
        </div>
    )
}

export default AppBody;