import { useState } from 'react';
import SmoothAnchor from './SmoothAnchor';
import { ReactComponent as Logo } from './images/swatchableio.svg';

function Header(): React.ReactElement {
  const [cssClass, setCSSClass] = useState<'' | 'open'>('');
  const [burgerMenuStatus, setBurgerMenuClass] = useState<'menu-inactive' | 'menu-active'>('menu-inactive');

  const toggleClass = (): void => {
    setCSSClass(cssClass === "" ? "open" : "");
    toggleBurgerMenu();
  };

  const toggleBurgerMenu = (): void => {
    setBurgerMenuClass(burgerMenuStatus === "menu-inactive" ? "menu-active" : "menu-inactive")
  };

  return (
    <div className="header">
      <div className="header-subspace">
        <div className="logo-space">
          <a href="https://swatchable.io/" className="logo-link">
            <Logo className="logo" />
            <p className="header-title">Swatchable.io</p>
          </a>
        </div>
        <div className={`nav-space ${burgerMenuStatus}`}>
          <div className="nav-logo">
            <Logo className="white-logo" />
            <p className="nav-header-title">Swatchable.io</p>
          </div>
          <nav className="nav-links">
            <ul>
              <li>
                <SmoothAnchor
                  url='#generate-swatches'
                  label='Generate Swatches'
                  customClass='nav-smooth-anchor'
                  clickAction={toggleClass}
                />
              </li>
              <li>
                <SmoothAnchor
                  url='#how-it-works'
                  label='How it Works'
                  customClass='nav-smooth-anchor'
                  clickAction={toggleClass}
                />
              </li>
              {/* <li>
                <SmoothAnchor
                  url='#technology'
                  label='Technology'
                  customClass='nav-smooth-anchor'
                  clickAction={toggleClass}
                />
              </li> */}
              <li>
                <SmoothAnchor
                  url='#feedback'
                  label='Feedback'
                  customClass='nav-smooth-anchor'
                  clickAction={toggleClass}
                />
              </li>
            </ul>
          </nav>
        </div>
        <div className="nav-burger-icon">
          <div className={`menu menu--close3 ${cssClass}`} onClick={toggleClass}>
            <div className="menu__icon">
              <div className="menu__line menu__line--1"></div>
              <div className="menu__line menu__line--2"></div>
              <div className="menu__line menu__line--3"></div>
            </div>
          </div>
        </div>
        <div className="nav-overlay" onClick={toggleClass}></div>
      </div>
    </div>
  );
}

export default Header;