
import { API_URL } from '../Config'
import axios from 'axios'
import stringify from 'csv-stringify'

export type SwatchList = {
  [imageName: string]: string
}

export async function getSwatchList(sid: string): Promise<SwatchList> {
  try {
    const response = await axios.get(`${API_URL}/output-objects`, {
      params: {
        sid
      }
    })
    return response.data
  } catch (e) {
    console.log(e)
    throw e
  }
}


export async function swatchListToCSV(swatchList: SwatchList): Promise<string> {
  const recordList = [["filename", "hexcode"]]
  Object.keys(swatchList).forEach(k => {
    const newRecord = [k, swatchList[k]]
    recordList.push(newRecord)
  })

  return new Promise((resolve, reject) => {
    stringify(recordList, (err, csv) => {
      if (err != null) {
        reject(err)
      } else {
        resolve(csv)
      }
    })
  })
}
