import { useEffect, useState } from 'react';
import { List, Avatar } from 'antd';
import { ReactComponent as Bin } from './images/recycle_bin.svg';

type Props = {
  image: File;
  uploadState: 'waiting' | 'pending' | 'uploading' | 'success' | 'error';
  hexCode: string | null;
  removeFile: () => void;
};

/**
 * Based on https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 */
function bytesToSize(bytes: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.round(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}

function FileUploadPreview({ image, uploadState, hexCode, removeFile }: Props): React.ReactElement {
  const [preview, setPreview] = useState<string | null>(null);
  const [copied, setCopied] = useState<boolean>(false);
  const [deferredFn, setDeferredFn] = useState<any>(null)

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);


  const copyToClipboard = async (str: string): Promise<void> => {
    setCopied(true)
    const newDeferredFn = setTimeout(() => {
      setCopied(false)
    }, 3000)
    clearTimeout(deferredFn)
    setDeferredFn(newDeferredFn)
    return await navigator.clipboard.writeText(str)
  }

  const getProgressSection = (): React.ReactElement => {

    switch (uploadState) {
      case 'uploading':
        return <span>Uploading</span>;
      case 'success':
        if (hexCode != null) {
          return (
            <>
              <span>#{hexCode}</span>
              <div className={`swatch-icon ${copied ? 'swatch-copied' : ''} `}
                style={{ backgroundColor: `#${hexCode}` }}
                onClick={() => copyToClipboard(hexCode)} ></div>
            </>
          )
        } else {
          return <span>Done</span>;
        }
      case 'pending':
        return <span>Pending</span>;
      case 'error':
        return <span>Error</span>;
      default:
        return <></>;
    }
  }

  return (
    <List.Item style={{ paddingRight: '10px' }}>
      <List.Item.Meta
        avatar={<Avatar src={preview} alt={'preview'} style={{ height: '50px', width: '50px' }} />}
        title={image.name}
        description={bytesToSize(image.size)}
      />
      {uploadState === 'waiting' ? (
        <Bin
          onClick={(e) => {
            e.stopPropagation();
            removeFile();
          }}
          height={20}
          width={20}
        />
      ) : (
        getProgressSection()
      )}
    </List.Item>
  );
}

export default FileUploadPreview;
