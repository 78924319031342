import { Anchor } from 'antd';

type Props = {
    url: string;
    label: string;
    customClass: string;
    clickAction?: () => void;
};

function SmoothAnchor({ url, label, customClass, clickAction }: Props): React.ReactElement {
    const { Link } = Anchor;
    return (
        <>
            <Anchor
                className={customClass}
                affix={false}
                offsetTop={30}
                onClick={clickAction}
            >
                <Link
                    href={url}
                    title={label}
                />
            </Anchor>
        </>
    )
}

export default SmoothAnchor;