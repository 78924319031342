import { ReactComponent as Logo } from './images/swatchableio.svg';
import SmoothAnchor from './SmoothAnchor';

function Footer(): React.ReactElement {

  return (
    <div className="footer">
      <div className="footer-subspace">
        <div className="footer-main-content">
          <div className="footer-logo">
            <Logo className="white-logo" />
            <p className="footer-logo-title">Swatchable.io</p>
          </div>
          <div className="footer-links">
            <div className="footer-link-block">
              <p className="block-title">Swatchable.io</p>
              <div className="block-links">
                <SmoothAnchor
                  url='#generate-swatches'
                  label='Generate Swatches'
                  customClass='footer-smooth-anchor'
                />
                <SmoothAnchor
                  url='#how-it-works'
                  label='How it Works'
                  customClass='footer-smooth-anchor'
                />
                <SmoothAnchor
                  url='#technology'
                  label='Technology'
                  customClass='footer-smooth-anchor'
                />
                <SmoothAnchor
                  url='#feedback'
                  label='Feedback'
                  customClass='footer-smooth-anchor'
                />
              </div>
            </div>
            <div className="footer-link-block">
              <p className="block-title">Technology</p>
              <div className="block-links">
                <a href="https://aws.amazon.com/serverless/">Architecture - AWS Serverless</a>
                <a href="https://pillow.readthedocs.io/en/stable/index.html">Image Processing - Python Pillow</a>
                <a href="https://ant.design/docs/react/introduce">Frontend - React / Ant Design</a>
              </div>
            </div>
            <div className="footer-link-block">
              <p className="block-title">Authors</p>
              <div className="block-links">
                <a href="https://github.com/mbowolick">
                  <span className="footer-item-icon"><span role="img" aria-label="github"><svg viewBox="64 64 896 896" focusable="false" data-icon="github" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5 64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9 26.4 39.1 77.9 32.5 104 26 5.7-23.5 17.9-44.5 34.7-60.8-140.6-25.2-199.2-111-199.2-213 0-49.5 16.3-95 48.3-131.7-20.4-60.5 1.9-112.3 4.9-120 58.1-5.2 118.5 41.6 123.2 45.3 33-8.9 70.7-13.6 112.9-13.6 42.4 0 80.2 4.9 113.5 13.9 11.3-8.6 67.3-48.8 121.3-43.9 2.9 7.7 24.7 58.3 5.5 118 32.4 36.8 48.9 82.7 48.9 132.3 0 102.2-59 188.1-200 212.9a127.5 127.5 0 0138.1 91v112.5c.8 9 0 17.9 15 17.9 177.1-59.7 304.6-227 304.6-424.1 0-247.2-200.4-447.3-447.5-447.3z"></path></svg></span></span>
                  Matty Bowolick
                </a>
                <a href="https://github.com/lukeknoot">
                  <span className="footer-item-icon"><span role="img" aria-label="github"><svg viewBox="64 64 896 896" focusable="false" data-icon="github" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5 64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9 26.4 39.1 77.9 32.5 104 26 5.7-23.5 17.9-44.5 34.7-60.8-140.6-25.2-199.2-111-199.2-213 0-49.5 16.3-95 48.3-131.7-20.4-60.5 1.9-112.3 4.9-120 58.1-5.2 118.5 41.6 123.2 45.3 33-8.9 70.7-13.6 112.9-13.6 42.4 0 80.2 4.9 113.5 13.9 11.3-8.6 67.3-48.8 121.3-43.9 2.9 7.7 24.7 58.3 5.5 118 32.4 36.8 48.9 82.7 48.9 132.3 0 102.2-59 188.1-200 212.9a127.5 127.5 0 0138.1 91v112.5c.8 9 0 17.9 15 17.9 177.1-59.7 304.6-227 304.6-424.1 0-247.2-200.4-447.3-447.5-447.3z"></path></svg></span></span>
                  Luke Knight
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="copywrite">
          <p>© {new Date().getFullYear()} Swatchable.io</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;