import { API_URL } from '../Config';
import axios from 'axios';

export type PresignedURLData = {
  url: string;
  fields: {
    // at runtime we have many other fields that we don't care about here
    [f: string]: string;
  } & {
    // but we know we have a key / bucket at least so we can account for that in the type
    key: string;
    bucket: string;
  };
};

export type PresignedURLAPIResponse = {
  [fileName: string]: PresignedURLData;
};

export async function getPresignedPostURLs(
  fileNames: Array<string>,
  sessionID: string,
): Promise<PresignedURLAPIResponse> {
  const result = await axios.post(`${API_URL}/presigned-upload`, {
    fileNames,
    sessionID,
  });
  return result.data;
}

export async function uploadFile(file: File, urlData: PresignedURLData): Promise<void> {
  const formData = new FormData();
  Object.keys(urlData.fields).forEach((field) => {
    formData.append(field, urlData.fields[field]);
  });
  // for the condition validation - don't allow upload of certain file types
  formData.append('Content-Type', file.type);
  formData.append('file', file);
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return await axios.post(urlData.url, formData, { headers });
}
